import React, { useState } from 'react'
import adminLoginImage from 'assets/admin/loginPageImage.png'

const AdminLogin = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('')

  const adminFormSubmission=()=>{

  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <section className="m-8 flex gap-4">
      <div className="w-full lg:w-3/5 mt-24">
        <div className="text-center">
          <h1 className="text-4xl text-danger font-bold mb-4">Admin Login</h1>
          <p color="blue-gray" className="text-lg font-normal">Enter your email and password to Sign In.</p>
        </div>
        <form onSubmit={adminFormSubmission} className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2">
          <div className="mb-1 flex flex-col gap-6">
            <h1 className="-mb-3 font-medium">
              Your email
            </h1>
            <input
              type='email'
              placeholder="name@mail.com"
              className="p-2 text-sm border-[2px] rounded"
              onChange={(e)=> setUserName(e.target.value)}
            />
            <h2 className="-mb-3 font-medium">
              Password
            </h2>
            <input
              type='password'
              placeholder="********"
              className="p-2 text-sm border-[2px] rounded"
              onChange={(e)=> setPassword(e.target.value)}
            />
          </div>

          <div className='flex items-center justify-start mt-3'>
            <input
              type="checkbox"
              id="termsCheckbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="mr-2 h-4 w-4"
            />
            <label htmlFor="termsCheckbox" className="text-sm font-medium">
              I agree the&nbsp;
              <a href="#"
                className="font-normal text-text"
              >
                Terms and Conditions
              </a>
            </label>
          </div>

          <button type='submit' className="mt-8 bg-blackBg w-full rounded p-2 text-white font-bold " >
            Sign In
          </button>

          <div className='flex items-center justify-between mt-6 '>
            <button className="flex items-center gap-2 justify-center shadow-md p-2 rounded text-sm font-bold text-text" fullWidth>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1156_824)">
                  <path d="M16.3442 8.18429C16.3442 7.64047 16.3001 7.09371 16.206 6.55872H8.66016V9.63937H12.9813C12.802 10.6329 12.2258 11.5119 11.3822 12.0704V14.0693H13.9602C15.4741 12.6759 16.3442 10.6182 16.3442 8.18429Z" fill="#4285F4" />
                  <path d="M8.65974 16.0006C10.8174 16.0006 12.637 15.2922 13.9627 14.0693L11.3847 12.0704C10.6675 12.5584 9.7415 12.8347 8.66268 12.8347C6.5756 12.8347 4.80598 11.4266 4.17104 9.53357H1.51074V11.5942C2.86882 14.2956 5.63494 16.0006 8.65974 16.0006Z" fill="#34A853" />
                  <path d="M4.16852 9.53356C3.83341 8.53999 3.83341 7.46411 4.16852 6.47054V4.40991H1.51116C0.376489 6.67043 0.376489 9.33367 1.51116 11.5942L4.16852 9.53356Z" fill="#FBBC04" />
                  <path d="M8.65974 3.16644C9.80029 3.1488 10.9026 3.57798 11.7286 4.36578L14.0127 2.08174C12.5664 0.72367 10.6469 -0.0229773 8.65974 0.000539111C5.63494 0.000539111 2.86882 1.70548 1.51074 4.40987L4.1681 6.4705C4.8001 4.57449 6.57266 3.16644 8.65974 3.16644Z" fill="#EA4335" />
                </g>

              </svg>
              <span>Sign in With Google</span>
            </button>
            <h2 className="text-sm font-medium text-text">
              <a href="#">
                Forgot Password ?
              </a>

            </h2>
          </div>
        </form>

      </div>
      <div className="w-2/5 h-full hidden lg:block">
        <img
          src={adminLoginImage}
          className="h-full w-full object-cover rounded-3xl"
        />
      </div>

    </section>
  );
}

export default AdminLogin
