import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import PakistanIcon from "../assets/Images/pkFlag-icon.png";
import StarIcon from "@mui/icons-material/Star";
import XIcon from "@mui/icons-material/X";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import EngagementModel from "Pages/EngagementModel";
// navbar images
import Mobiledevelopment from "assets/nav/appDevelopment.png";
import Enterprisedevelopment from "assets/nav/enterpriseSoftware.png";
import Blockchain from "assets/nav/blockchain.png";
import Developmentteam from "assets/nav/developmentteam.png";
import ScubeLogo from "assets/nav/scubelogo.png";
import PhoneLogo from "assets/nav/icons8-phone-80.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useContactFormContext } from "../service/ContactFormContext";
import { apiPostRequest } from "service/apiService";

export default function Navbar() {
  const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);
  // const { contactNumbers } = useContactFormContext();
  // const contactNumbers = [
  //   {
  //     phoneNo: ['+92 3027655876', '+92 3126747241'],
  //   },
  // ];

  const openEngagementModel = () => {
    console.log("Opening Engagement Model");
    setEngagementModelOpen(true);
  };

  const closeEngagementModel = () => {
    console.log("Closing Engagement Model");
    setEngagementModelOpen(false);
  };
  // text animation below
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition((prevPosition) => prevPosition - 1);
    }, 30);

    return () => clearInterval(intervalId);
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [subscriptionMails, setSubscriptionMails] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleSubscriptionSubmit = async (e) => {
    e.preventDefault();

    if (subscriptionMails.trim() === '') {
      setValidationError('Subscription failed due to invalid email address.');
    }
    else {
      try {
        await apiPostRequest(
          'subscription',
          { UserEmails: subscriptionMails }
        );
        // Clear input field after successful submission
        setSubscriptionMails('');
        setValidationError('');
      }
      catch (error) {
        console.error('Subscription Error:', error);
        setValidationError('Failed to subscribe. Please try again later.');
      }
    }
  };


  return (
    <>
      <header className=" sticky top-0 z-50 w-full">
        <div>
          <div className="bg-primaryBg tablet:pr-16 sm:pr-2 flex items-center justify-end">
            <ul className="flex items-center space-x-2 text-white p-1">
              <li>
                <figure>
                  <img className="w-7" src={PhoneLogo} alt="Phone Icon" />
                </figure>
              </li>
              <li className="border-[2px] h-6 mx-2"></li>
              <li className="flex items-center gap-x-1">
                <figure>
                  <img
                    src={PakistanIcon}
                    alt="PakistanIcon"
                    className="w-6"
                  />
                </figure>
                <a
                  className="tablet:text-sm sm:text-xs font-bold"
                  href={`tel:+92 3027655876`}
                >
                  +92 3027655876
                </a>
              </li>
              <li className="border-[2px] h-6 mx-2"></li>

              <li className="flex items-center gap-x-1">
                <figure>
                  <img
                    src={PakistanIcon}
                    alt="PakistanIcon"
                    className="w-6"
                  />
                </figure>
                <a
                  className="tablet:text-sm sm:text-xs font-bold"
                  href={`tel:+92 3126747241`}
                >
                  +92 3126747241
                </a>
              </li>


              {/* {contactNumbers.map((phoneItems, index) => (
                <div key={index}>
                  <li className="flex items-center gap-x-1">
                    <figure>
                      <img
                        src={PakistanIcon}
                        alt="PakistanIcon"
                        className="w-6"
                      />
                    </figure>
                    <a
                      className="tablet:text-sm sm:text-xs font-bold"
                      href={`tel:${phoneItems.phoneNo1}`}
                    >
                      {phoneItems.phoneNo1}
                    </a>
                  </li>
                  
                </div>
              ))} */}
            </ul>
          </div>
          <nav className="md:px-5 sm:px-2 bg-white shadow md:flex md:items-center md:justify-between">
            <div className="flex justify-between items-center">
              <Link to="/">
                <figure>
                  <img src={ScubeLogo} alt="ScubeLogo" className="h-8 inline" />
                </figure>
              </Link>
              <div className="flex flex-row justify-center items-center md:hidden sm:block sm:py-3">
                <button
                  className="bg-gradiBg  rounded-lg text-white px-2 py-2 mr-3"
                  onClick={() => setEngagementModelOpen(true)}
                >
                  For Entrepreneurs
                </button>
                <span
                  className="md:text-5xl bg-primaryBg p-[5px] rounded-lg text-white h-12 w-10 md:flex md:items-center md:justify-center md:rounded"
                  onClick={toggleMenu}
                >
                  <MenuIcon />
                </span>
              </div>
            </div>
            <ul
              className={`md:flex md:items-center z-[-1] md:z-auto md:static absolute bg-white w-full left-0 md:w-auto md:py-1 md:pl-0 pl-7 md:opacity-100 ${isMenuOpen ? "opacity-100 top-auto" : "opacity-100 top-[-400px]"
                } transition-all ease-in duration-500`}
            >
              <li className="mx-2 my-6 md:my-0 relative sm:hidden md:block">
                <button
                  id="dropdownHoverButton"
                  onMouseEnter={() => setIsDropdownOpen(true)}
                  onMouseLeave={() => setIsDropdownOpen(false)}
                  className="text-dark hover:text-primaryText font-semibold rounded-lg py-2.5 inline-flex gap-x-5 items-center justify-between relative"
                >
                  <span> Services</span> &nbsp;
                  <span
                    id="dropdownIcon"
                    className={`absolute top-0 right-0 flex items-center h-full transition-transform duration-300 ${isDropdownOpen ? "navIcon" : ""
                      }`}
                  >
                    <svg
                      className="w-2.5 h-3.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </span>
                </button>
                {isDropdownOpen && (
                  <div
                    id="dropdownHover"
                    className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-[500px] dark:bg-gray-700 block top-[45px] left-[120px] md:left-0 md:right-0 transition duration-300 ease-in-out`}
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                    <ul
                      className="p-2 text-sm text-text dark:text-gray-200"
                      aria-labelledby="dropdownHoverButton"
                    >
                      <div className="flex gap-3 flex-row block">
                        <li>
                          <Link
                            to="mobile-appDevelopment"
                            className=" flex items-center gap-x-2 py-2 hover:text-secondaryText dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            <figure>
                              <img
                                className="h-7 w-7"
                                src={Mobiledevelopment}
                                alt="Mobiledevelopment"
                              />
                            </figure>
                            <span>Mobile App Development</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="blockchainDevelopment-service"
                            className="flex items-center gap-x-2 py-2 hover:text-secondaryText dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            <figure>
                              <img
                                className="h-7 w-7"
                                src={Blockchain}
                                alt="Mobiledevelopment"
                              />
                            </figure>
                            <span>Blockchain Development Services</span>
                          </Link>
                        </li>
                      </div>
                      <div className="flex gap-3 flex-row block">
                        <li>
                          <Link
                            to="enterprise-softwareDevelopment"
                            className="flex items-center gap-x-2 py-2 hover:text-secondaryText dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            <figure>
                              <img
                                className="h-7 w-7"
                                src={Enterprisedevelopment}
                                alt="Enterprisedevelopment"
                              />
                            </figure>
                            <span>Enterprise Software Development</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="dedicatedTeam"
                            className="flex items-center gap-x-2 py-2 hover:text-secondaryText dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            <figure>
                              <img
                                className="h-7 w-7"
                                src={Developmentteam}
                                alt="DedicatedTeam"
                              />
                            </figure>
                            <span>Dedicated Development Team</span>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </div>
                )}
              </li>
              <div className="lg:hidden">

                {/* Hide on large screens */}
                <li className="mx-2 my-6 md:my-0">
                  <div className="relative">
                    <button
                      id="dropdownDefaultButton"
                      data-dropdown-toggle="dropdown"
                      className="font-semibold rounded-lg flex flex-row items-center justify-between"
                      type="button"
                      onClick={() => {
                        const dropdown = document.getElementById("dropdown");
                        const dropdownIcon =
                          document.getElementById("dropdownIcon");
                        const engagementModal =
                          document.getElementById("engagementModal");

                        if (dropdown.classList.contains("hidden")) {
                          // Dropdown is closed, open it
                          dropdown.classList.remove("hidden");
                          engagementModal.style.marginTop =
                            dropdown.offsetHeight + "px";
                          dropdownIcon.classList.add("rotate-180");
                        } else {
                          // Dropdown is open, close it and reset positions
                          dropdown.classList.add("hidden");
                          engagementModal.style.marginTop = "";
                          dropdownIcon.classList.remove("rotate-180");
                        }
                      }}
                    >
                      <span>Services</span>
                      <span
                        id="dropdownIcon"
                        className="absolute top-0 right-0 flex items-center h-full transition-transform duration-300"
                      >
                        <svg
                          className="w-2.5 h-3.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </span>
                    </button>

                    <div
                      id="dropdown"
                      className="z-[-10] hidden absolute left-0 py-2 divide-y divide-gray-100 rounded-lg w-full"
                    >
                      <ul
                        className="text-xs flex justify-center flex-col sm:gap-y-3 tablet:gap-y-4 font-semibold text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li className="flex items-center gap-x-1">
                          <figure>
                            <img
                              className="h-6 w-6"
                              src={Mobiledevelopment}
                              alt="Mobiledevelopment"
                            />
                          </figure>
                          <Link
                            to="mobile-appDevelopment"
                            className="block hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => setIsMenuOpen(false)}>
                            Mobile App Development
                          </Link>
                        </li>
                        <li className="flex items-center gap-x-1">
                          <figure>
                            <img
                              className="h-6 w-6"
                              src={Blockchain}
                              alt="Blockchain"
                            />
                          </figure>
                          <Link
                            to="blockchainDevelopment-service"
                            className="blockhover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => setIsMenuOpen(false)}>
                            Blockchain Development Services
                          </Link>
                        </li>
                        <li className="flex items-center gap-x-1">
                          <figure>
                            <img
                              className="h-6 w-6"
                              src={Enterprisedevelopment}
                              alt="Enterprisedevelopment"
                            />
                          </figure>
                          <Link
                            to="enterprise-softwareDevelopment"
                            className="block hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => setIsMenuOpen(false)}>
                            Enterprise Software Development
                          </Link>
                        </li>
                        <li className="flex items-center gap-x-1">
                          <figure>
                            <img
                              className="h-6 w-6"
                              src={Developmentteam}
                              alt="Developmentteam"
                            />
                          </figure>
                          <Link
                            to="dedicatedTeam"
                            className="block hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => setIsMenuOpen(false)}>
                            Dedicated Development Team
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </div>
              <li
                id="engagementModal"
                className="hover:text-primaryText font-semibold cursor-pointer mx-2 my-6 md:my-0"
              >
                <p onClick={openEngagementModel}>Engagement Model</p>
              </li>
              <li
                id="portfolio"
                className="hover:text-primaryText font-semibold cursor-pointer mx-2 my-6 md:my-0"
                onClick={() => setIsMenuOpen(false)}>
                <Link to="portfolio">Portfolio</Link>
              </li>
              <li className="flex items-center justify-center mx-auto my-6 md:my-0">

                {/* Modified this line */}
                <Link
                  to="contactUs"
                  className="bg-gradiBg bg-primaryBg font-semibold hover:bg-none rounded-lg text-white lg:w-full md:mx-2 px-3 py-2 md:my-20 md:w-[10%] my-0"
                  onClick={() => setIsMenuOpen(false)}>
                  Contact Us
                </Link>
              </li>
              <li className="hidden md:block hover:text-primaryText text-container border-y-primaryText border-2 shadow-inner">
                <Link
                  to="entrepreneurs"
                  className="animated-text font-semibold"
                  style={{ transform: `translateX(${position}px)` }}
                >
                  For Entrepreneurs
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <Outlet />

      {isEngagementModelOpen && (
        <EngagementModel onClose={closeEngagementModel} />
      )}
      <footer>
        <div className="bg-dark text-white text-xs">
          <div className="bg-[#002033]">
            <div className="container mx-auto text-start lg:grid lg:grid-cols-5 lg:gap-3 py-4 sm:flex sm:items-center sm:flex-col md:flex-row md:items-stretch md:justify-between">
              <div>
                <h1 className="text-white text-4xl font-semibold mb-3">
                  <span className="text-secondaryText">S</span>Cube
                </h1>
                <p className="text-sm leading-normal text-white font-productsans mb-3">
                  Ready to transform your business digitally?{" "}
                </p>
                <div className="flex gap-2">
                  <h1 className="text-white text-4xl font-semibold mb-3">
                    Clutch
                  </h1>
                  <div className="flex flex-col justify-around items-center">
                    <span className="text-yellow">
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                    </span>
                    <p className="text-sm leading-normal text-white font-productsans">
                      31 Reviews
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:block sm:hidden">
                <h3 className="text-2xl font-semibold text-white leading-normal mb-3">
                  Recent e-guides
                </h3>
                <ul className="text-sm leading-normal text-white font-productsans">
                  <li className="mb-3">
                    {/* makemoneyfromapp */}
                    <Link to="/" className="hover:text-aPrimary">
                      App Monetization Strategies: How to Make Money From an
                      App?
                    </Link>
                  </li>
                  <li className="mb-3">
                    {/* electric-vehicle */}
                    <Link to="/" className="hover:text-aPrimary">
                      Electric Vehicle Software Development – A Comprehensive
                      Guide
                    </Link>
                  </li>
                  <li className="mb-3">
                    {/*enhance-theuk */}
                    <Link to="/" className="hover:text-aPrimary">
                      How to Enhance the UX of a Mobile App with Design
                      Thinking: A Comprehensive Guide
                    </Link>
                  </li>
                  <li className="mb-3">
                    {/* artificial-intelligence */}
                    <Link to="/" className="hover:text-aPrimary">
                      Artificial Intelligence (AI) – The Next Big Thing in
                      Logistics
                    </Link>
                  </li>
                  <li className="mb-3">
                    {/* cloud-basedmobile */}
                    <Link to="/" className="hover:text-aPrimary">
                      Mastering Cloud-Based Mobile App Development: An Insider’s
                      Guide
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="lg:block sm:hidden">
                <h3 className="text-2xl font-semibold text-white leading-normal mb-3">
                  Services
                </h3>
                <ul className="text-sm leading-normal text-white font-productsans">
                  <li className="mb-3">
                    <Link
                      to="mobile-appDevelopment"
                      className="hover:text-aPrimary"
                    >
                      Mobile App Development
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="blockchainDevelopment-service"
                      className="hover:text-aPrimary"
                    >
                      Blockchain Development Services
                    </Link>
                  </li>
                  <li className="mb-3">
                    <a
                      href="enterprise-softwareDevelopment"
                      className="hover:text-aPrimary"
                    >
                      Enterprise Software Development
                    </a>
                  </li>
                  <li className="mb-3">
                    <Link to="dedicatedTeam" className="hover:text-aPrimary">
                      Dedicated Development Team
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-2xl font-semibold text-white  leading-normal mb-3">
                  Expertise
                </h3>
                <ul className="text-sm leading-normal text-white font-productsans">
                  <li className="mb-3 cursor-pointer">
                    <p
                      onClick={openEngagementModel}
                      className="hover:text-aPrimary"
                    >
                      Metaverse App Development
                    </p>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="arvr-appdevelopment"
                      className="hover:text-aPrimary"
                    >
                      AR / VR App Development
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link to="nft-marketplace" className="hover:text-aPrimary">
                      NFT Marketplace Development
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="iot-appdevelopment"
                      className="hover:text-aPrimary"
                    >
                      IoT App Development
                    </Link>
                  </li>
                  <li className="mb-3 cursor-pointer">
                    <p
                      onClick={openEngagementModel}
                      className="hover:text-aPrimary"
                    >
                      AI App Development
                    </p>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-2xl font-semibold  leading-normal mb-3">
                  Subscribe US
                </h3>
                <p className="text-sm leading-normal  font-productsans mb-3">
                  Make the right business move.
                </p>
                {/* Subscription Mail */}
                <div className="w-full">
                  <form onSubmit={handleSubscriptionSubmit} className="bg-blue-100 h-10 turncate flex items-stretch justify-between rounded-md">
                    <input
                      className="w-full border-0 p-3 text-base text-red-500 bg-transparent h-full outline-none"
                      title="Please Enter Your Email Address Here!"
                      type="email"
                      value={subscriptionMails}
                      onChange={(e) => setSubscriptionMails(e.target.value)}
                      name="userEmails"
                      placeholder="Email address"
                    />
                    <button className="w-[50%] bg-primaryBg text-base font-semibold h-full rounded-r-md" type="submit">
                      Send
                    </button>
                  </form>
                  {validationError && (
                    <h1 className="text-danger font-semibold text-xs pt-2 px-2">
                      {validationError}
                    </h1>
                  )}
                  <p className="text-gray-500 border-b border-gray-500-500 p-2 font-productsans text-xs">
                    Your email ID is confidential.
                  </p>
                  <ul className="flex items-center justify-between my-3 text-dark">
                    <li>
                      <Link to={"#!"}>
                        <span className="hover:text-secondaryText rounded-full bg-white p-2  h-8 w-8 flex justify-center items-center">
                          <XIcon fontSize="small" />
                        </span>
                      </Link>
                    </li>

                    <li>
                      <Link to="#!">
                        <span className="hover:text-secondaryText rounded-full bg-white p-2  h-8 w-8 flex justify-center items-center">
                          <FacebookSharpIcon fontSize="small" />
                        </span>
                      </Link>
                    </li>

                    <li>
                      <a href="http://linkedin.com/company/scubepk">
                        <span className="hover:text-secondaryText rounded-full p-2 bg-white h-8 w-8 flex justify-center items-center">
                          <LinkedInIcon fontSize="small" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <Link to="#!">
                        <span className="hover:text-secondaryText rounded-full p-2 bg-white h-8 w-8 flex justify-center items-center">
                          <YouTubeIcon fontSize="small" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!">
                        <span className="hover:text-secondaryText rounded-full p-2 bg-white h-8 w-8 flex justify-center items-center">
                          <InstagramIcon fontSize="small" />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="container mx-auto">
              <hr />
              <div className="flex sm:flex-col tablet:flex-row justify-center items-center gap-2 sm:py-2 md:py-0">
                <div>
                  <p className="tablet:py-4 sm:font-bold tablet-font-semibold">
                    &#169;2024{" "}
                    <span className="text-secondaryText font-bold">S</span>Cube
                    All Rights Reserved
                  </p>
                </div>
                <div className="flex sm:gap-5 font-semibold">
                  <Link
                    to="/privacypolicy"
                    className="hover:text-text tablet:p-4"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to="/termsandconditions"
                    className="hover:text-text tablet:p-4"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    to="/termsofservices"
                    className="hover:text-text tablet:p-4"
                  >
                    Terms of Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}