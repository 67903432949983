import './App.css';
import React from 'react'
import { Routes, Route } from 'react-router-dom'
// Pages import below
import Dashboard from './component/Dashboard'
import Navbar from './component/Navbar'
import EngagementModel from './Pages/EngagementModel';
import NftMarketPlace from './Pages/NftMarketPlace';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfServices from './Pages/TermsOfServices';
import ARVRfooter from './Pages/ARVRfooter';
import ElectricVehicleFooter from './Pages/ElectricVehicleFooter'
import IotAppDevelopmentFooter from './Pages/IotAppDevelopmentFooter';
import MakeMoneyFromAppFooter from './Pages/MakeMoneyFromAppFooter';
import EnhanceTheUkFooter from './Pages/EnhanceTheUkFooter';
import ArtificialInteligenceFooter from './Pages/ArtificialInteligenceFooter';
import CloudBasedMobileFooter from './Pages/CloudBasedMobileFooter';
import Portfolio from './Pages/Portfolio'
import MobileAppDevelopment from './Pages/MobileAppDevelopment';
import PageNotFound from './Pages/PageNotFound';
import DedicatedTeam from './Pages/DedicatedTeam'
import BlockChainDevelopmentService from 'Pages/BlockChainDevelopmentService';
import ContactUs from 'component/ContactUs';
import EnterpriseSoftwareDevelopment from 'Pages/EnterpriseSoftwareDevelopment';
import AdminLogin from 'admin/auth/AdminLogin';
function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Navbar />}>
          <Route index element={<Dashboard />} />
          <Route path='makemoneyfromapp' element={<MakeMoneyFromAppFooter />} />
          <Route path='electric-vehicle' element={<ElectricVehicleFooter />} />
          <Route path='enhance-theuk' element={<EnhanceTheUkFooter />} />
          <Route path='artificial-intelligence' element={<ArtificialInteligenceFooter />} />
          <Route path='cloud-basedmobile' element={<CloudBasedMobileFooter />} />
          <Route path='nft-marketplace' element={<NftMarketPlace />} />
          <Route path='iot-appdevelopment' element={<IotAppDevelopmentFooter />} />
          <Route path='arvr-appdevelopment' element={<ARVRfooter />} />
          <Route path='termsandconditions' element={<TermsAndConditions />} />
          <Route path='privacypolicy' element={<PrivacyPolicy />} />
          <Route path='termsofservices' element={<TermsOfServices />} />
          <Route path='portfolio' element={<Portfolio />} />
          <Route path='engagement-model' element={<EngagementModel />} />
          <Route path='nftmarketplace' element={<NftMarketPlace />} />
          <Route path='termsandconditions' element={<TermsAndConditions />} />
          <Route path='privacypolicy' element={<PrivacyPolicy />} />
          <Route path='termsofservices' element={<TermsOfServices />} />
          <Route path='contactUs' element={<ContactUs />} />
          {/* Services Dropdown */}
          <Route path='blockchainDevelopment-service' element={<BlockChainDevelopmentService />} />
          <Route path='dedicatedTeam' element={<DedicatedTeam />} />
          <Route path='mobile-appDevelopment' element={<MobileAppDevelopment />} />
          <Route path='enterprise-softwareDevelopment' element={<EnterpriseSoftwareDevelopment />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>

        {/* ADMIN SERVICES ROUTES */}
        <Route path='/admin' element={<AdminLogin/>}>
          
          {/* <Route path='login' element={<AdminLogin />} /> */}
          {/* Add more admin routes */}
        </Route>

      </Routes>
    </>
  );
}

export default App;
